<template>
  <Card>
    <h1>設定圖</h1>
    <div v-for="(item, index) in items" class="item" :key="index">
      <h2>
        <span class="tag" v-if="item.main">為目前主要使用</span
        ><span class="tag" v-if="item.edited">被我自己修改過</span>
      </h2>
      <h2>
        作者 : {{ item.author.name }}
        <a :href="item.author.link">連結至原作者</a>
      </h2>
      <a :href="item.image" target="_blank"><img :src="item.image" /></a>
    </div>
  </Card>
</template>

<script>
import Card from "./../components/Card";

export default {
  components: { Card },
  data() {
    return {
      items: [
        {
          author: {
            name: "龍龍 Dragon X2",
            link: "https://www.facebook.com/DragonX02",
          },
          edited: false,
          main: false,
          image: "/assets/refsheet/dragonx2.png",
        },
        {
          author: {
            name: "龍龍 Dragon X2",
            link: "https://www.facebook.com/DragonX02",
          },
          edited: true,
          main: true,
          image: "/assets/refsheet/dragonx2_edited.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.item {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.1rem 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
span {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-right: 0.5em;
}
.tag{
  line-height:2em;
  white-space:nowrap;
}
</style>