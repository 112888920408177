import {
    createApp
} from 'vue'

// Import App
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Import CSS
import "normalize.css"
import "./scss/main.scss"

const app = createApp(App)
app.use(router);

app.mount('#app');