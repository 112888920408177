<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
.card {
  background: rgba(0, 0, 0, 0.3);
  padding: 1em 2em;
  border-radius: 4px;
  color: $color-font;
  margin-bottom: 2em;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
}
</style>
