<template>
  <div id="nav">
    <div class="avater">
      <img class="frontAvater" src="/assets/avater/front_avatar.png" alt="" />
      <img class="rearAvater" src="/assets/avater/rear_avater.png" alt="" />
    </div>

    <div class="content">
      <span class="title">山松 MountainPine</span>
      <div class="items">
        <router-link
          v-for="item in items"
          :key="item.name"
          :to="item.link"
          custom
          v-slot="{ href, navigate }"
        >
          <a :href="href" @click="navigate">
            <div class="item">
              {{ item.name }}
            </div>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: "首頁 Home",
          link: "/",
        },
        {
          name: "關於 About",
          link: "/about",
        },
        {
          name: "設定圖 RefSheet",
          link: "/refSheet",
        },
        {
          name: "聲明 Credit",
          link: "/credit",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

#nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin: 2rem 0rem;
}

.avater {
  background: $color-font;
  border-radius: 8px;
  padding: 0.5rem;
  width: 8rem;
  height: 8rem;
  position: relative;
  margin-right: 1em;
}
.avater img {
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
}

.frontAvater {
  transform: rotate3d(0, 1, 0, 0deg);
}
.avater:hover > .frontAvater {
  transform: rotate3d(0, 1, 0, 90deg);
}
.rearAvater {
  transform: rotate3d(0, 1, 0, -90deg);
}
.avater:hover > .rearAvater {
  transform: rotate3d(0, 1, 0, 0deg);
}

.items {
  flex-wrap: wrap;
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
}
.item {
  background: rgba(255, 255, 255, 0.2);
  padding: 1em 1.2em;
  margin-right: 1.2em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
  border-radius: 0.1rem;
}
.item:hover {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 2px 10px rgba(255, 255, 255, 0.2);
}
.item:active {
  background: rgba(255, 255, 255, 0.5);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 8rem;
}
.title {
  font-size: $font-title;
  font-weight: bold;

  color: $color-font;
}

@media only screen and (max-width: 768px) {
  #nav {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .avater {
    margin-bottom: 1em;
    margin-right: 0 !important;
  }
  .content {
    height: auto !important;
    align-items: center !important;
  }
  .title {
    font-size: $font-title-mobile !important;
  }
  .items {
    justify-content: center;
  }
  .item {
    margin-right: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
}

a:link,
a:visited,
a:focus,
a:hover,
a:active,
a {
  text-decoration: none;
}
</style>