import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import RefSheet from '../views/RefSheet.vue'
import Credit from '../views/Credit.vue'

const routes = [{
    path: '/',
    name: '首頁 Home',
    component: Home
  },
  {
    path: '/about',
    name: '簡介 About me',
    component: About
  },
  {
    path: '/refSheet',
    name: '設定圖 Ref Sheet',
    component: RefSheet
  },
  {
    path: '/credit',
    name: '聲明 Credit',
    component: Credit
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.name + " | 山松 MountainPine"
  next()
})

export default router