<template>
  <Card>
    <Markdown :source="source" :html="true"  />
    <span style="display:none">於 2021/04/13 不小心正式入坑</span>
    <span style="display:none">於 2021/05/07 設定出來ㄌ</span>
    <span style="display:none">於 2021/05/12 委託毛毛</span>
    <span style="display:none">於 2022/01/02 DTD 完成！</span>
    <span style="display:none">於 2022/03/03 誕生！</span>
    <Contact />
  </Card>
</template>

<script>
import Card from "./../components/Card";
import Contact from "./../components/Contact";
import AboutSource from "raw-loader!./../static/about.md";
import Markdown from "vue3-markdown-it";

export default {
  components: { Card, Contact, Markdown },
  data() {
    return {
      source: AboutSource,
    };
  },
};
</script>

<style>
</style>