<template>
  <div class="center">
    <div class="container">
      <Nav />
      <router-view />
    </div>
  </div>
</template>

<script>
import Nav from "./components/Nav";
export default {
  components: { Nav },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
.container {
  width: 1280px;
}
@media only screen and (max-width: 1280px) {
  .container {
    width: 95%;
  }
}
</style>
