<template>
  <Card>
    <h1>聲明</h1>
    <h2>圖片使用</h2>
    <h3>
      左邊揉臉臉及大頭貼翻轉後的軟爛照片作者為 :
      <a href="https://www.plurk.com/ArrowOlga">沃犬 </a>
    </h3>
    <h3>
      大頭貼圖片作者為 :
      <a href="https://www.plurk.com/MIXDOG093">DOG</a>
    </h3>
    <h3>
      右方圖片作者為 :
      <a href="https://www.facebook.com/DragonX02">龍龍 Dragon X2</a>
    </h3>
    <h2>其他使用</h2>
    <h3>
      聯絡圖示來源為 :
      <a href="https://simpleicons.org/">Simple Icons</a>
    </h3>
  </Card>
</template>

<script>
import Card from "./../components/Card";

export default {
  components: { Card },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}
</style>