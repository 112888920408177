<template>
  <div class="content">
    <h1>聯絡方式 Contact</h1>
    <div class="items">
      <a
        class="button"
        v-for="item in items"
        :key="item.name"
        :href="item.href"
        :target="item.noNewTab ? '' : '_blank'"
      >
        <component class="icon" :is="item.component" />
        {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
import MailIcon from "../icons/gmail.svg";
import TwitterIcon from "../icons/twitter.svg";
import PlurkIcon from "../icons/plurk.svg";
import FacebookIcon from "../icons/facebook.svg";
import DiscordIcon from "../icons/discord.svg";
import TelegramIcon from "../icons/telegram.svg";

export default {
  components: {
    MailIcon,
    TwitterIcon,
    PlurkIcon,
    FacebookIcon,
    DiscordIcon,
  },
  data() {
    return {
      items: [
        {
          component: PlurkIcon,
          name: "Plurk",
          href: "https://www.plurk.com/MountainPine",
        },
        {
          component: TelegramIcon,
          name: "Telegram",
          href: "https://t.me/MountainPine",
        },
        {
          component: TwitterIcon,
          name: "Twitter",
          href: "https://twitter.com/MountainPineTW",
        },
        {
          component: FacebookIcon,
          name: "Facebook",
          href: "https://www.facebook.com/MountainPineTW/",
        },
        {
          component: DiscordIcon,
          name: "Discord",
          noNewTab: true,
          href: "javascript:alert('山松 MountainPine#4958')",
        },
        { component: MailIcon, name: "Mail", href: "mailto:hi@mptw.me" }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h1{
  line-height: 3rem;
}
.content {
  margin-top: 3rem;
}
.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  fill: white;
}
.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
a:link,
a:visited,
a:focus,
a:hover,
a:active,
a {
  text-decoration: none;
}
</style>