<template>
  <Card>
    <h1>嗨嗨！</h1>
    <h3>這裡是山松！平常也可以叫我松松</h3>
    <h3>來自台灣東部，是一隻狐狸，混合著一些松鼠的特色 (?)</h3>
    <h3>更多資訊請點擊上方的關於</h3>

    <Contact />
  </Card>
</template>

<script>
import Card from "./../components/Card";
import Contact from "./../components/Contact";

export default {
  components: { Card, Contact },
};
</script>

<style>
</style>